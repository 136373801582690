<template>
    <slot v-if="!noButton" :open="() => {showDialog=true}">
        <button @click="() => {showDialog=true}" v-bind="$attrs">{{$t('Share with')}}</button>
    </slot>
    <ODialog v-model:show="showDialog" ref="modalRef" @show="handleShown" @hidden="handleHidden" size="lg" :title="$t('Share with Persons')"
        backdrop>
        <div class="o365-dialog-body">
            <div class="d-inline-flex align-items-center" style="height: 40px">
                <div class="form-check form-switch form-check-inline ms-auto">
                    <input class="form-check-input" v-model="enableMultiselect" type="checkbox" id="multiselectSwitch" />
                    <label class="form-check-label" style="padding-bottom: 2px !important" for="multiselectSwitch">Multiselect mode</label>
                </div>
                <div v-if="enableMultiselect" class="ms-3 mb-1">
                    <OShareWithPersonsLookup class="form-control" 
                                            :whereClause="lookupWhereClause" 
                                            :bind="handleSelect"
                                            :multiselect="enableMultiselect"
                                            hideToggleMultiselect />
                </div>
            </div>
            <ODataGrid ref="sharedWithGridRef" :dataObject="dsSharedWith" hideGridMenu :hideNewRecords="enableMultiselect" showNewRecordsPanel>
                <OColumn name="PersonName" editable width="400" flexWidth="100" disableResize>
                    <template #editor="{row}">
                        <OShareWithPersonsLookup v-model="row.PersonName" :whereClause="lookupWhereClause" 
                            :bind="(sel)=>{row.Person_ID = sel.ID; row.PersonName = sel.Name; row.Layout_ID = props.layoutId; handleSelect(sel) }"
                            hideToggleMultiselect :multiselect="multiselect"
                        />
                    </template>
                </OColumn>
            </ODataGrid>
        </div>
    </ODialog>
</template>

<script setup lang="ts">
import { OModal, ODialog } from 'o365-ui-components';
import { OPersonsLookup, OShareWithPersonsLookup } from  'o365-system-lookups';
import { userSession } from 'o365-modules';
import { ref, computed, onMounted, watch } from 'vue';
import {API} from 'o365-modules';
import { useDataObjectEventListener } from 'o365-vue-utils';

const props = defineProps<{
    layoutId: number;
    noButton: boolean;
}>();

const emit = defineEmits<{
    (e: 'hidden' ): void
}>();

const modalRef = ref<OModal|null>(null);
const showDialog = ref(false);
const sharedWithGridRef = ref(null);
const personsList = ref([] as any)
const lookupWhereClause = computed(() => {
    let condition = `ID <> ${userSession.personId}`;
    if (personsList.value.length > 0) {
        const exclusions = personsList.value.map(id => `ID <> ${id}`).join(' AND ');
        condition += ` AND (${exclusions})`;
    }
    return condition;
})
const dsSharedWith = $getOrCreateDataObject({
    id: 'o_dsLayouts_SharedWith',
    appId: 'site',
    viewName: 'sviw_O365_SharedLayouts',
    uniqueTable: 'stbv_O365_SharedLayouts',
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    maxRecords: 50,
    fields: [
        { name: 'PrimKey' }, 
        { name: 'ID', type: 'number' },
        { name: 'Person_ID', type: 'number' },
        { name: 'Layout_ID', type: 'number' },
        { name: 'PersonName' }
    ]
});
useDataObjectEventListener(dsSharedWith, 'DataLoaded', (data, options) => {
    console.log(data)
    personsList.value = data.map((item)=>(item.Person_ID))

});

const enableMultiselect = ref(false);

const multiselect = computed(()=>{
    if(dsSharedWith.currentIndex){
        return dsSharedWith.currentIndex < 0 && enableMultiselect.value;
    } else 
    return false;
});

const handleSelect = async (selection) =>{
    const data = selection.map( item => ({Layout_ID: props.layoutId, Person_ID: item.ID}))
    const body ={
        operation: "create",
        viewName: "stbv_O365_SharedLayouts",
        uniqueTable: "stbv_O365_SharedLayouts",
        bulk: true,
        fields: [
            { name: "PrimKey" },
            { name: "Layout_ID" },
            { name: "Person_ID" }
    ],
        values: {data: data}
    }

    try {
        const response = await API.requestPost("/api/data",JSON.stringify(body));
    } catch {

    }
    dsSharedWith.cancelChanges();
    dsSharedWith.load();
    
}



function handleShown() {
    if (props.layoutId) {
        dsSharedWith.recordSource.whereClause = `[Layout_ID] = '${props.layoutId}'`;
        dsSharedWith.load();
    } else {
        dsSharedWith.storage.clearItems();
        dsSharedWith.batchData.storage.clearItems();
    }
}

watch(enableMultiselect, (newValue, oldValue) => {
    if(sharedWithGridRef.value){
         sharedWithGridRef.value?.dataGridControl.closeBatchRecords();
    }
    if(!enableMultiselect.value){
        sharedWithGridRef.value?.dataGridControl.enableBatchRecords()
    }
})

function handleHidden() {
    dsSharedWith.storage.clearItems();
    dsSharedWith.batchData.storage.clearItems();
    emit('hidden');
}

function show() {
    showDialog.value = true;
}


defineExpose({ modalRef, show});
</script>